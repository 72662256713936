import 'fullpage.js/vendors/easings.min.js';
import 'fullpage.js/vendors/scrolloverflow.js';
import '../vendor/fullpage.offsetSections.min.js';
import fullpage from 'fullpage.js/dist/fullpage.extensions.min.js';
import isPage from '../util/isPage';
import { isDesktop } from '../util/getBreakpoint';
import { addVerticalTabsScrollEvents } from './vertical-tabs-content';
import { handleFixedNav, updateProgressBars } from './fixed-section-nav';
import revealNavOnScroll from './reveal-nav-on-scroll';
import parallaxSections from './parallax-sections';
import customCursor from './custom-cursor';
import { addContentPanelScrollEvents } from './animate-content-panels';
import staggerText from './staggerText';
import initMap from '../modules/map';

export default () => {
    if (location.hostname === 'everyondr.com') {
        return;
    }

    const fixedNav = document.querySelector('.c-secondary-nav--fixed');
    const sectionTarget = '.fp-section';
    const normalScrollElementClass = isDesktop
        ? '.fp-normal-scroll-element-desktop'
        : '.fp-normal-scroll-element-mobile';
    const scrollingSpeed = 900;
    let scrollingDisabled = false;
    let mapLoaded = false;

    // const enableScrollOverflow = document.domain === 'everyondr.com' ? false : true;

    const fp = new fullpage('#fp', {
        licenseKey: process.env.FULLPAGE_LICENSE_KEY,
        offsetSectionsKey: process.env.FULLPAGE_OFFSET_SECTIONS_KEY,
        offsetSections: true,
        sectionSelector: sectionTarget,
        verticalCentered: false,
        scrollingSpeed,
        scrollOverflow: true,
        bigSectionsDestination: 'top',
        normalScrollElements: normalScrollElementClass,
        fixedElements: '.wt-cli-cookie-bar-container',
        onLeave: (origin, destination, direction) => {
            allowScroll(destination.item);

            if (isPage('home')) {
                handleFixedNav(destination.index, 1, 4, fixedNav);
                updateProgressBars(destination.index - 1, fixedNav);
                parallaxSections.animateIn(destination.index, destination.item);
            }

            if (isPage('page-template-about-us')) {
                if (isDesktop && destination.item.classList.contains('c-yondr-way')) {
                    addVerticalTabsScrollEvents();
                }
            }

            if (isPage('joinyondr')) {
                if (isDesktop) {
                    handleFixedNav(destination.index, 1, 2, fixedNav);
                    if (destination.item.classList.contains('c-join-yondr')) addContentPanelScrollEvents();

                    if (destination.item.classList.contains('c-what-we-do') && direction === 'down') {
                        staggerText(destination.item.querySelector('.c-what-we-do__col-1'), 0.2);
                        staggerText(destination.item.querySelector('.c-what-we-do__col-2'), 0.2);
                    }
                }
            }

            if (isPage('page-template-locations-map')) {
                if (destination.item.classList.contains('c-locations') && !mapLoaded) {
                    initMap();
                    mapLoaded = true;
                }
            }
        },
        afterRender: () => {
            customCursor();
            if (!isPage('home')) {
                revealNavOnScroll();
            }
        },
        scrollBar: true,
        autoScrolling: false,
        fitToSection: false,
    });

    function allowScroll(section) {
        if (!isDesktop) return;
        let allow =
            section.classList.contains('fp-disable') && !scrollingDisabled
                ? false
                : !section.classList.contains('fp-disable') && scrollingDisabled
                ? true
                : null;

        if (allow === null) return;

        fp.setAllowScrolling(allow);
        scrollingDisabled = !allow;
    }
};

export function reBuildFPonClick(selectors) {
    selectors.forEach((selector) => {
        const elem = document.querySelectorAll(selector);
        elem.forEach((item) => {
            item.addEventListener('click', () => setTimeout(() => window.fullpage_api.reBuild(), 50));
        });
    });
}
