import { gsap, Power1 } from 'gsap';

let section;
let prevNavItems;
let nextNavItems;
let heroCropBG;
let prevArrows;
let nextArrows;
let panelCount;
const backgroundColours = ['red', 'green', 'blue', 'gold']; // get this from data attribute when linking to cms

let activeIndex = 0;
let prevIndex = 0;
let isTransitioning = false;

function initializeElements() {
  try {
    section = document.querySelector('.c-content-panels');
    if (!section) {
      console.warn('Content panels section not found');
      return false;
    }
    
    const prevElements = document.querySelectorAll('.c-content-panels .c-content__prev h3');
    const nextElements = document.querySelectorAll('.c-content-panels .c-content__next h3');
    
    if (!prevElements.length || !nextElements.length) {
      console.warn('Navigation items not found');
      return false;
    }
    
    // Convert NodeLists to Arrays safely
    prevNavItems = Array.prototype.slice.call(prevElements);
    nextNavItems = Array.prototype.slice.call(nextElements);
    
    heroCropBG = document.querySelector('.c-hero-crop-cta');
    prevArrows = document.querySelectorAll('.js-panel-prev');
    nextArrows = document.querySelectorAll('.js-panel-next');
    
    const slides = document.querySelectorAll('.c-content-panels__slide');
    if (!slides.length) {
      console.warn('No content panel slides found');
      return false;
    }
    panelCount = slides.length;

    return true;
  } catch (error) {
    console.warn('Error initializing elements:', error);
    return false;
  }
}

function initialize() {
  if (!initializeElements()) {
    return;
  }

  prevNavItems.forEach((item, index) => handleClick(item, index));
  nextNavItems.forEach((item, index) => handleClick(item, index));
  
  prevArrows.forEach((prevArrow) => bindPrevArrow(prevArrow));
  nextArrows.forEach((nextArrow) => bindNextArrow(nextArrow));
}

export function addContentPanelScrollEvents() {
  if (!initializeElements()) {
    return;
  }
  changePanel(null, activeIndex);
}

function handleClick(navItem, index) {
  navItem.addEventListener('click', () => {
    if (index === prevIndex) return;
    if (isTransitioning) return;
    const direction = index > activeIndex ? 'down' : 'up';

    activeIndex = index;
    changePanel(prevIndex, activeIndex, direction);
    prevIndex = activeIndex;
  });
}

function changePanel(prev, active, direction) {
  if (isTransitioning) {
    return;
  }

  isTransitioning = true;

  animateContent(prev, false, direction);
  animateContent(active, true, direction);
  handleNavItems(direction);
  handleBGColor();
  handleArrowDisable();

  setTimeout(() => isTransitioning = false, 1000);
}

function handleNavItems(direction) {
  prevNavItems.forEach((item, index) => {
    if (direction === 'down' && index === activeIndex - 1) animateNav(item, true, true);
    else if (direction === 'up' && index === activeIndex) animateNav(item, false, false);
    if (index < activeIndex) resetNavItem(item);
    if ((direction === 'down' && index >= activeIndex) || (direction === 'up' && index >= activeIndex + 1))
      item.style.display = 'none';
  });

  nextNavItems.forEach((item, index) => {
    if (direction === 'down' && index === activeIndex) animateNav(item, false, true);
    else if (direction === 'up' && index === activeIndex + 1) animateNav(item, true, false);
    if (index > activeIndex) resetNavItem(item);
    if ((direction === 'down' && index < activeIndex) || (direction === 'up' && index <= activeIndex))
      item.style.display = 'none';
  });
}

function handleBGColor() {
  backgroundColours.forEach((color) => {
    if (heroCropBG) heroCropBG.classList.remove(`u-bg-${color}`);
    if (section) section.classList.remove(`u-bg-${color}`);
  });
  
  if (section) section.classList.add(`u-bg-${backgroundColours[activeIndex]}`);
  if (heroCropBG) heroCropBG.classList.add(`u-bg-${backgroundColours[activeIndex]}`);
}

function animateContent(index, animateIn) {
  if (index === null) return;
  if (!section) return;

  try {
    const anim = gsap.timeline({ paused: true });
    const sectionContent = section.querySelectorAll('.c-content')[index];
    if (!sectionContent) {
      console.warn('Section content not found for index:', index);
      return;
    }

    const header = sectionContent.querySelector('.c-content__text-header');
    const text = sectionContent.querySelector('.c-content__text-body');
    const image = sectionContent.querySelector('.c-content__image');
    const navArrows = sectionContent.querySelector('.c-content__nav-arrows');

    if (!header || !text || !image || !navArrows) {
      console.warn('Required elements not found in section content');
      return;
    }

    const xStart = animateIn ? '-5%' : '0%';
    const xEnd = animateIn ? '0%' : '-5%';
    const opacityStart = animateIn ? 0 : 1;
    const opacityEnd = animateIn ? 1 : 0;
    const delay = animateIn ? 0.4 : 0;

    // Create animation timeline
    anim
      .fromTo(
        header,
        { autoAlpha: opacityStart, y: animateIn ? 15 : 0 },
        { autoAlpha: opacityEnd, y: animateIn ? 0 : 15, ease: Power1.easeOut, duration: 0.4 },
        delay
      )
      .fromTo(
        text,
        { autoAlpha: opacityStart, x: xStart },
        { autoAlpha: opacityEnd, x: xEnd, ease: Power1.easeOut, duration: 0.4 },
        delay + 0.15
      )
      .fromTo(
        image,
        { autoAlpha: opacityStart, x: xStart },
        { autoAlpha: opacityEnd, x: xEnd, ease: Power1.easeOut, duration: 0.4 },
        delay + 0.3
      )
      .fromTo(
        navArrows,
        { autoAlpha: opacityStart, x: xStart },
        { autoAlpha: opacityEnd, x: xEnd, ease: Power1.easeOut, duration: 0.4 },
        delay + 0.3
      );

    // Play the animation
    anim.play();
  } catch (error) {
    console.warn('Error during animation:', error);
    // Reset elements to visible state in case of error
    if (animateIn) {
      const elements = section.querySelectorAll('.c-content')[index]?.querySelectorAll(
        '.c-content__text-header, .c-content__text-body, .c-content__image, .c-content__nav-arrows'
      );
      elements?.forEach(el => {
        el.style.opacity = '1';
        el.style.transform = 'none';
      });
    }
  }
}

function animateNav(item, fadeIn, fadeUp) {
  const anim = gsap.timeline();
  const opacityStart = fadeIn ? 0 : 1;
  const opacityEnd = fadeIn ? 1 : 0;
  const visibility = fadeIn ? 'visible' : 'hidden';
  const callback = fadeIn ? 'onStart' : 'onComplete';
  const yStart = getNavItemYAxisValues(fadeIn, fadeUp).yStart;
  const yEnd = getNavItemYAxisValues(fadeIn, fadeUp).yEnd;

  anim
    .fromTo(
      item,
      { autoAlpha: opacityStart, y: yStart },
      { autoAlpha: opacityEnd, y: yEnd, ease: Power1.easeOut },
      0
    )
    .eventCallback(callback, () => {
      item.style.visibility = visibility;
    });
}

function getNavItemYAxisValues(fadeIn, fadeUp) {
  if (fadeUp) {
    if (fadeIn) return { yStart: 30, yEnd: 0 };
    else return { yStart: 0, yEnd: -30 };
  } else {
    if (fadeIn) return { yStart: -30, yEnd: 0 };
    else return { yStart: 0, yEnd: 30 };
  }
}

function resetNavItem(item) {
  item.style.display = 'inline-block';
  item.style.opacity = '1';
  item.style.visibility = 'visible';
  item.style.transform = 'translate(0, 0)';
}

function bindPrevArrow(prevArrow) {
  prevArrow.addEventListener('click', () => {
    previousPanel();
  });
}

function bindNextArrow(nextArrow) {
  nextArrow.addEventListener('click', () => {
    nextPanel();
  });
}

function nextPanel() {
  if (isTransitioning) return;
  if (activeIndex === (panelCount - 1)) return;

  activeIndex = activeIndex + 1;
  changePanel(prevIndex, activeIndex, 'down');
  prevIndex = activeIndex;
}

function previousPanel() {
  if (isTransitioning) return;
  if (activeIndex === 0) return;

  activeIndex = activeIndex - 1;
  changePanel(prevIndex, activeIndex, 'up');
  prevIndex = activeIndex;
}

function handleArrowDisable() {
  prevArrows.forEach((prevArrow) => {
    if (activeIndex === 0) {
      prevArrow.classList.add('inactive');
    } else {
      prevArrow.classList.remove('inactive');
    }
  });

  nextArrows.forEach((nextArrow) => {
    if (activeIndex === (panelCount - 1)) {
      nextArrow.classList.add('inactive');
    } else {
      nextArrow.classList.remove('inactive');
    }
  });
}

// Initialize when the DOM is ready
function init() {
  // Wait for a small delay to ensure all resources are loaded
  setTimeout(() => {
    try {
      initialize();
    } catch (error) {
      console.warn('Error initializing content panels:', error);
    }
  }, 100);
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}
